.nav-bar {
  align-items: center;
  background-color: black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  display: flex;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  height: 60px;
  justify-content: space-between;
  padding: 0 25px;
  position: sticky;
  top: 0;

  @media screen and (max-width: 1300px) {
    border-radius: 0px;
  }

  .site-nav {
    display: flex;
    justify-content: space-between;
    width: 250px;

    @media screen and (max-width: 500px) {
      width: 200px;
    }
  }

  button {
    background-color: black;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.1rem;
    outline: none;
    text-decoration: none;

    @media screen and (max-width: 500px) {
      font-size: 0.9rem;
    }
    
  }

  .underline {
    height: 2px;
    width: 2px;
    background-color: black;
    transition: width 1s, background-color 1s;
  }
  
  .nav-item:hover{
    .underline {
      width: 100%;
      background-color: rgb(35, 253, 28);
    }
  }
}
