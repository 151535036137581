@import "./Variables.scss";

.contact-container {
  background-color: black;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 50px;

  @media screen and (max-width: 1300px) {
    border-radius: 0px;
  }

  h1 {
    background-color: white;
    border-radius: 2px;
    color: black;
    display: inline-block;
    font-family: "Merriweather", serif;
    font-size: 1.6rem;
    padding: 5px 20px;
  }

  .img-flex {
    align-items: center;
    display: flex;

    @media screen and (max-width: 784px) {
      flex-direction: column;
      margin-top: 30px;
    }
  }

  .img-container {
    text-align: center;
    width: 50%;

    img {
      border-radius: 100px;
      width: 200px;

      @media screen and (max-width: 784px) {
        border-radius: 50px;
        width: 100px;
      }
    }
  }

  .info {
    padding: 30px;

    a {
      align-items: center;
      color: white;
      display: flex;
      justify-content: center;
      text-decoration: none;
    }

    span {
      font-family: "Roboto", sans-serif;
      font-size: 1.3rem;
      margin: 20px;

      @media screen and (max-width: 600px) {
        font-size: 1rem;
        margin: 10px;
      }
    }

    @media screen and (max-width: 500px) {
      padding: 30px 0;
    }
  }
}
