.App {
  margin: 0 auto;
  max-width: 1300px;
}

.background {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;

  @media screen and (max-width: 1430px) {
    height: 100%;
    width: auto;
  }
}
