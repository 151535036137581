.header-page {
  background-color: rgba(71, 71, 71, 0.76);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 800px;
  margin: 0 auto;
  width: 99%;

  @media screen and (max-width: 1300px) {
    border-radius: 0px;
    width: 100%;
  }

  .logos {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    text-align: center;
    width: 100%;

    a {
      text-decoration: none;
      transition: all .4s;

      &:hover {
        transform: skewY(12deg);

        .logo {
          color: rgb(0, 255, 0);
        }
        
        // p {
        //   font-size: .8rem;
        // }
      }
    }

    .logo {
      color: rgb(214, 214, 214);
      margin: 20px 20px 1.5px;
    }

    p {
      color: rgb(194, 194, 194);
      font-family: "Roboto", sans-serif;
      font-size: 0.7rem;
      margin-top: 5px;
    }
  }
}

.title {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 550px;
  justify-content: center;

  h1 {
    font-family: "Merriweather", serif;
    font-size: 2.4rem;
    margin: 10px;

    @media screen and (max-width: 400px) {
      font-size: 2rem;
    }
  }

  h2 {
    color: rgb(35, 253, 28);
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin: 10px;
  }
}
