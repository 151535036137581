.about-container {
  background-color: black;
  border-radius: 5px;
  color: white;
  padding: 100px 80px;
  margin-top: 350px;

  @media screen and (max-width: 1300px) {
    border-radius: 0px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 250px;
    padding: 80px 30px;
  }

  .about {
    background-color: white;
    border-radius: 2px;
    display: flex;
    width: 200px;
    padding: 5px;
  }

  .about-text {
    color: black;
    font-family: "Merriweather", serif;
    font-size: 1.6rem;
  }

  .about-body {
    align-items: center;
    display: flex;
    margin: 50px 0;
    padding: 20px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    h2 {
      font-family: "Merriweather", serif;
      font-size: 1.5rem;
      line-height: 1.3;
      padding-right: 30px;
      text-align: right;
      width: 50%;

      @media screen and (max-width: 700px) {
        font-size: 1.4rem;
        padding: 0 0 50px;
        width: 100%;
      }
    }

    p {
      border-left: 1px solid white;
      font-family: "Roboto", sans-serif;
      font-size: 1.1rem;
      line-height: 1.2;
      padding: 20px 0 20px 30px;
      width: 50%;

      @media screen and (max-width: 700px) {
        border-left: none;
        border-top: 1px solid white;
        padding: 50px 0 0;
        width: 100%;
      }
    }
  }
}
